import {css} from '@emotion/react'
import {fonts, colors, transitions, fontWeights, unitConverter} from '@utils/base'

export const imageWrapperCss = css`
  width: 100%
  max-width: 100%;
  border: ${unitConverter('1px')} solid ${colors.lightGray};
  margin-bottom: ${unitConverter('10px')};
  position relative;
  line-height: 0;
  overflow: hidden;

  img {
    width: 100%;
    max-width: 100%;
    line-height: 0;
    transition: all ${transitions.fast} ease-in-out;
  }

  span {
    position: relative;
    bottom: 0;
    left: 0;
    padding: ${unitConverter('5px')};
    background-color: ${colors.black};
  }
`

export const productWrapperCss = css`
  border: ${unitConverter('1px')} solid ${colors.lightGray};
  padding: ${unitConverter('10px')};
  background-color: ${colors.darkGray};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

  &:hover {
    &.even {
      .image-wrapper {
        img {
          transform: scale(1.5) rotate(-2.5deg);
         }
      }
    }
    &.odd {
      .image-wrapper {
        img {
          transform: scale(1.5) rotate(2.5deg);
         }
      }
    }
  }
`

export const productTitleWrapperCss = css`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-family: ${fonts.tektonPro};
    font-size: 1.4em;
    font-weight: ${fontWeights.normal};
    color: ${colors.lightGrayHover};
    width: 55%;
  }

  p {
    font-family: ${fonts.tektonPro};
    font-size: .85em;
    color: ${colors.lightGray};
    width: 45%;
    text-align: right;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`

export const buttonWrapperCss = css`
  margin: ${unitConverter('10px 0 0 0')};

  a {
    display: block;
    padding: ${unitConverter('10px 0')};
    border: none;
    border-radius: ${unitConverter('5px')};
    background-color: ${colors.lightGray};
    color: ${colors.darkGray};
    border: ${unitConverter('1px')} solid ${colors.darkGray};
    transition: all ${transitions.fast} ease-in-out;
    outline: none;
    font-family: ${fonts.signo};
    font-size: .9em;
    cursor: pointer;
    font-weight: ${fontWeights.bold};
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    width: 100%;
    position: relative;
    bottom: 0;

    &:hover {
      background-color: ${colors.lightGrayHover};
    }
  }
`
