/* @jsx jsx */
import React from 'react'
import {jsx} from '@emotion/react'
import {graphql, useStaticQuery} from 'gatsby'
import {productsWrapperCss} from './styles'
import {mapEdgesToNodes} from '@utils/helpers'
import Product from '@molecules/Product'

const Products = () => {
  const data = useStaticQuery(query)
  const products = mapEdgesToNodes(data.products)

  const getProducts = () => {
    return products.map((product, index) => (
      <Product
        {...product}
        key={product.id}
        className={index % 2 === 0 ? 'even' : 'odd'}
      />
    ))
  }

  return (
    <section css={productsWrapperCss}>
      {products.length > 0 && getProducts()}
    </section>
  )
}

export default Products

export const query = graphql`
  query AllProductsQuery {
    products: allShopifyProduct {
      edges {
        node {
          title
          handle
          id
          priceRange {
            minVariantPrice {
              amount
              currencyCode
            }
            maxVariantPrice {
              amount
              currencyCode
            }
          }
          variants {
            id
            title
            priceV2 {
              amount
              currencyCode
            }
            availableForSale
            shopifyId
            selectedOptions {
              name
              value
            }
          }
          images {
            originalSrc
            id
          }
        }
      }
    }
  }
`
