import {css} from '@emotion/react'
import {breakpoints, unitConverter} from '@utils/base'
export const productsWrapperCss = css`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${unitConverter('40px')};

  > div {
    max-width: calc((100% / 4) - ${unitConverter('20px')});
    width: 100%;
    padding: ${unitConverter('10px')};
    margin: ${unitConverter('10px 10px 15px')};

    ${breakpoints.tabletLandscape} {
      max-width: calc((100% / 3) - ${unitConverter('20px')})
    }

    ${breakpoints.tabletPortrait} {
      max-width: calc((100% / 2) - ${unitConverter('20px')})
    }

    ${breakpoints.mobile} {
      max-width: calc(100% - ${unitConverter('20px')})
    }
  }
`
