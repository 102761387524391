/* @jsx jsx */
import React from 'react'
import {jsx} from '@emotion/react'
import {
  productWrapperCss,
  imageWrapperCss,
  productTitleWrapperCss,
  buttonWrapperCss
} from './styles';
import Image from '@atoms/image'
import {Link} from 'gatsby'

const Product = ({title, handle, images, variants, className}) => {
  const [minPrice, maxPrice, currency] = [
    Math.min.apply(0, variants.map(variant => variant.priceV2.amount)),
    Math.max.apply(0, variants.map(variant => variant.priceV2.amount)),
    ...new Set(variants.map(variant => variant.priceV2.currencyCode))
  ]

  const handleClick = () => {
    window.location = `/product/${handle}`
  }

  const minPriceFormatted = Intl.NumberFormat(undefined, {
    currency,
    minimumFractionDigits: 2,
    style: 'currency',
  }).format(minPrice)

  const maxPriceFormatted = Intl.NumberFormat(undefined, {
    currency,
    minimumFractionDigits: 2,
    style: 'currency',
  }).format(maxPrice)

  return (
    <div css={productWrapperCss} className={className} onClick={handleClick}>
      {images.length > 0 && (
        <div className={`image-wrapper`} css={imageWrapperCss}>
          <Link to={`/product/${handle}`}>
            <Image src={images[0].originalSrc} alt={`${title} product image`} maxWidth={'100%'} />
          </Link>
        </div>
      )}
      <div css={productTitleWrapperCss}>
        <h2><Link to={`/product/${handle}`}>{title}</Link></h2>
        <p>{minPriceFormatted} {minPrice !== maxPrice && (<> &mdash; {maxPriceFormatted}</>)}</p>
      </div>
      <div css={buttonWrapperCss}>
        <Link to={`/product/${handle}`}>View</Link>
      </div>
    </div>
  )
}

export default Product
